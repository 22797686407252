'use strict'
const _ = require('lodash')

const SCREEN_WIDTH = 2500
const STRIP_COLUMN_CONTAINER_TYPE = 'wysiwyg.viewer.components.StripColumnsContainer'

const isPageContainingDefectiveStrip = (components, propertiesData) => {
    const strips = _.filter(components, isStripColumnContainer)
    const containers = _.filter(components, isContainer)

    if (!_.isEmpty(strips)) {
        return isDefectiveStripColumnContainer(_.head(strips), propertiesData)
    }
    return _.some(containers, container => isPageContainingDefectiveStrip(container.components, propertiesData))
}

const fixDesktopPageJson = (pageJson, pageWidth) => {
    const propertiesData = getPropertiesData(pageJson)
    const defectiveStrips = findAllDefectiveStripColumnContainers(getDesktopChildren(pageJson), propertiesData)
    _.forEach(defectiveStrips, _.partial(fixStripColumn, pageWidth, propertiesData))
    return pageJson
}

const getPropertiesData = pageJson => _.get(pageJson, 'data.component_properties', {})

const getDesktopChildren = pageJson => _.get(pageJson, ['structure', isMasterPage(pageJson) ? 'children' : 'components'], [])

const findAllDefectiveStripColumnContainers = (components, propertiesData) => {
    const strips = _.filter(components, c => isDefectiveStripColumnContainer(c, propertiesData))
    const containers = _.filter(components, isContainer)

    _.forEach(containers, container => {
        const defectiveChildren = findAllDefectiveStripColumnContainers(container.components, propertiesData)
        strips.push(...defectiveChildren)
    })

    return strips
}

const isStripColumnContainer = comp => _.get(comp, 'componentType', null) === STRIP_COLUMN_CONTAINER_TYPE

const isDefectiveStripColumnContainer = (component, propertiesData) =>
    isStripColumnContainer(component) &&
    (_.size(component.components) === 1 && isStripSameWidthAsFirstChild(component) && isStretchedToScreen(component, propertiesData)) ||
    _.size(component.components) >= 2 && isStripSameWidthAsFirstChild(component)

const isStripSameWidthAsFirstChild = component => _.get(component, 'layout.width') === _.get(_.head(component.components), 'layout.width', null)
const isContainer = component => _.get(component, 'type', null) === 'Container' && component.components

const getDesktopPageWidth = pageJson => isMasterPage(pageJson) ?
    _.get(getPagesContainerComp(pageJson), 'layout.width', null) :
    _.get(pageJson, 'structure.layout.width', null)

const isMasterPage = pageJson => pageJson.structure.id === 'masterPage'

const getPagesContainerComp = masterPageJson => _.find(_.get(masterPageJson, 'structure.children', []), {id: 'PAGES_CONTAINER'})

const fixStripColumn = (pageWidth, propertiesData, stripColumnContainer) => {
    stripColumnContainer.layout.width = isStretchedToScreen(stripColumnContainer, propertiesData) ? SCREEN_WIDTH : pageWidth
}

const isStretchedToScreen = (component, propertiesData) => {
    const props = propertiesData[component.propertyQuery.replace('#', '')]
    return _.get(props, 'fullWidth', false)
}

module.exports = {
    exec(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel, magicObject) {
        if (magicObject.isExperimentOpen('sv_stripColumnContainerWidthFixer')) {
            const pageWidth = getDesktopPageWidth(pageJson)
            const shouldPageBeFixed = () => isPageContainingDefectiveStrip(getDesktopChildren(pageJson), getPropertiesData(pageJson))
            if (magicObject.isViewerMode && _.isNumber(pageWidth) && shouldPageBeFixed()) {
                return fixDesktopPageJson(pageJson, pageWidth)
            }
        }
        return pageJson
    }
}